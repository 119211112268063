$pin: rgb(220, 93, 94);
$hole: #ffffff;
$shadow:rgb(58, 58, 58);
$pulse: rgba(220, 93, 94, 0.5);

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateY(0) rotate(-45deg);
        
    }

}


@keyframes shadow {
    0% {
        opacity: 0;
    }
    
    50% {
        opacity: 0;
        transform: scale(0.25);
    }
    
    100% {
        opacity: 1;
        transform: scale(1);
        
    }
    
}
    
.map {

    min-height: 500px;

    
    .gm-style .gm-style-iw-c {
        border-radius: 0 !important;
    }

    .overlay-view:hover {
        z-index: 10000 !important;
    }

}

.popover {
    border-radius: 0;
    box-shadow: 0 10px 20px rgba(#000,0.2);
}

.place-info {
    width: 16em;
    padding: 0;
    font-family: var(--font-headline);

    > * {
        margin: 1em;
    }

    h3 {
        text-transform: uppercase;
    }

    h3, .h3 {
        font-size: 1.0rem;
    }

    p {
        line-height: 120%;
        margin-bottom: 0.4em;
    }

    .card-img-top {
        max-height: 10em;
        object-fit: cover;
        margin: 0 !important;
    }

    button.close {
        position: absolute;
        width: 1.5em;
        right: 0;
        top: 0.2em;
        font-size: 30px;
        margin: 0 !important;
        text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff;
        opacity: 1;

        &:hover {
            opacity: 0.8;
        }
    }

}

.map-laender {
    .place-info {
        .text {
            text-transform: uppercase;
        }
    }
}

.lazyblock-legend {

    .legend {
        @include desktop {
            margin: 1em 2em;   
        }

        @include mobile {
            margin: 1em 0; 
            @include media-breakpoint-down(xs) {
                > .col {
                    flex-basis: 100%;
                    margin: 1em 0; 
                }
            }
        }
    }
}

.marker {

    position: relative;
    width: 0;
    height: 0;

    &.hover, &.legend {
        .title {
            opacity: 1;
        }
    }

    &.hover {
        z-index: 100;
        .pin {
            transform: translateY(-10px) rotate(-45deg) scale(1.2);
            background: lighten($color:$pin, $amount: 10);
        }
    }

    

    .pin {
        width: 30px;
        height: 30px;
        border-radius: 50% 50% 50% 0;
        background: $pin;
        position: absolute;
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        cursor: pointer;
        //box-shadow: 0 0 0 #000;
        border: 2px solid rgba(#000, 0.5);
        box-sizing: content-box;
        //transition: all 0.4s ease-in-out;
        z-index: 1;

    }


    .title {
        position: absolute;
        padding: 4px 10px 4px 35px;
        font-family: var(--font-headline);
        display: block;
        text-align: left;
        left: -5px;
        top: -18px;
        line-height: 25px;
        font-size: 16px;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        white-space: nowrap;
        user-select: none;
        pointer-events: none;
    }

    &:not(.legend) {
        .title {
            top: -25px;
            box-shadow: 0 5px 10px rgba(#000, 0.5);
            background: #ffffff;
        }
    }


    .pin:after {
        content: "";
        width: 14px;
        height: 14px;
        margin: 8px 0 0 8px;
        background: $hole;
        position: absolute;
        border-radius: 50%;
    }

    .bounce {
        animation-name: bounce;
        animation-duration: 2s;
    }

    .shadow {
        background: $shadow;
        filter: blur(5px);
        border-radius: 50%;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 11px 0px 0px -12px;
        transform: rotateX(55deg);
        z-index: -2;
        animation-name: shadow;
        animation-fill-mode: both;
        animation-duration: 2s;
    }
    .pulse:after {
        content: "";
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -2px 0 0 -23px;
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        border: 4px solid $pulse;
        animation-delay: 1.1s;
    }

    .reference {
        position: absolute;
        height: 40px;
        width: 0;
        top: 0;
        left: -8px;
        margin-top: -20px;
    }
    
}
  
.lazyblock-map {
    @include desktop() {
        @include make-container();
        @include make-container-max-widths();
    }
    .frame .inner {
        padding-bottom: 1em !important;
    }
}

.post-id-324 .lazyblock-map {
    margin-bottom: 10em !important;
}
