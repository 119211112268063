.protection {
    max-width: 620px;
    button[type="submit"] {
        background: #353535;
        color: #fff;
        font-weight: bold;
        max-width: 200px;
    }
    input {
        border: 1px solid #cccfd3;
    }
}
