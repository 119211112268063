.welcome-video {

    .modal-content {
        overflow: hidden;
        position: relative;
        border-radius: 0;
    }

    .modal-body {
        padding: 0;
        margin-top: -2px;
    }

    .modal-header {
        position: absolute;
        z-index: 10;
        width: 100%;
        button {
            color: white;
        }
    }

    .wrapper {
        height: 0;
        padding-bottom: 56.5%;
        position: relative;
        video {
            position: absolute;
            width: 100%;
            height: calc(100% + 2px);
        }
    }
}

.modal-dialog {
    align-items: center;
    height: 100%;
    display: flex;
    max-width: 800px;
    margin: 0 auto;
}