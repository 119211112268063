.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.heart {
        background-image: url(./images/heart.svg);
        height: 20px;
        top: 4px;
        position: relative;        
    }
    &.heart-outline {
        background-image: url(./images/heart-outline.svg);
        height: 20px;
        top: 4px;
        position: relative;        
    }
    &.read-later {
        background-image: url(./images/read-later.svg);
        height: 20px;
        top: 4px;
        position: relative;        
    }
}
