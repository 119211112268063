.quiz {
    display: block;
    text-align: center;
    @include desktop() {
        height: 500px;
        overflow: hidden;
        position: relative;
    }

    .btn {
        font-weight: bold;
      }

    .index {
        text-align: center;
        h5 {
            font-size: 80%;
        }
    }

    .ask, .resolution, .results {
        @include desktop() {
            padding: 0 42% 0 0;
        }
        @include mobile() {
            text-align: center;
        }
        align-self: center;
    }

    .ask, .resolution, .choices {
        text-align: left;
        @include mobile() {
            text-align: center;
        }
        margin: 1em 0;
    }

    .choices {
        .btn {
            display: block;
            margin: 0.2em 1em 0.2em 0;
            background: black;
            @include mobile() {
                margin: 0.2em auto 0.2em auto;
            }
        }
    }

    .results {
        @include desktop() {
            text-align: left;
        }
        @include mobile() {
            & {
                margin: 0 2em;
            }
            .image {
                margin: 2em;
            }
        }
    }

    .next {
        @include desktop() {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
        text-align: center;
    }

    .image {
        @include desktop() {
            @include overlay();
        }
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom right;
        }
    }



}