.post > {

    *:not(.core-image) {
        margin-top: 3em;
        margin-bottom: 2em;
    }


    .core-heading {
        &:not(:first-child) {
            margin-top: 4em;
        }
        h2 {
            font-family: var(--font-headline);
            text-align: center;
            font-weight: bold;
        }
    }

    .core-paragraph {
        p {
            line-height: 150%;
        }

    }

    .core-paragraph, .core-quote, .core-list, .lazyblock-page-content, .core-image .is-style-text-width {
        max-width: calc(71.250em - 16em*2);
        margin: 0 auto;
    }

    .core-paragraph + .core-paragraph {
        margin-top: 0;
    }

    .core-column {
        @include make-col-ready();
        @include make-col(6);
        padding-right: 0;
        padding-left: 0;
    }
    
}

.post-wrap {
    padding-bottom: 3em;
}

.core-spacer {
    display: none;
}
