.social-icons {
  ul {
    padding: 0;
    margin: 0;
    display: flex;

    * {
      display: inline-flex;
    }

    li {
      font-size: 40px;

      &,
      svg {
        width: 40px;
        height: 40px;
      }

      @include desktop() {
        font-size: 50px;

        &,
        svg {
          width: 50px;
          height: 50px;
        }
      }

      padding: 0;
      margin-right: 0.3em;
      text-decoration: none;

      a {
        line-height: 100%;
        font-family: rewegroup_icon !important;
        color: #6F6F6F;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

.start-one,
.start-certified {

  img {
    width: auto;
    height: 70px;
    padding-right: 1em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-bottom: 0.5em;
    }
  }
}

.start-certified {
  .core-image {
    float: left;
  }

  .core-paragraph {
    margin-bottom: 0.5em;
    min-height: 70px;
    display: flex;
    align-items: center;
  }

}

.start-one a {
  @include media-breakpoint-up(sm) {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    img {
      display: inline-block;
    }
  }

  span {
    display: inline-block;
    margin-right: 20px;
  }
}

.logo {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 112px;
  height: 30px;
  transition: all 0.3s ease-in-out;

  span {
    display: none;
  }
}

.rewe-digital .logo {
  background-image: url(./images/rewe-digital-logo.svg);
}

.rewe-group .logo {
  background-image: url(./images/rewe-group-logo.svg);
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.rewe-digital {

  .logo-wrapper {
    margin-left: 10px;

    @include mobile {
      margin-left: 20px;
      margin-top: 10px;
      padding-bottom: 10px;
    }
  }

}

@include desktop() {
  .scrolled-top {

    .logo {
      width: 162px;
      height: 44px;
    }

  }
}

.footer-links {
  @include media-breakpoint-up(md) {
    text-align: right;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.page-start {
  h2 {
    font-size: 24px;
    margin-top: 1.0em;
    margin-bottom: 1.0em;
  }
}

.start-banner {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  margin: 0;
  transition: all 0.5s;


  .jpg-preview {
    width: 100%;
    height: auto;
  }

  .wrap-svg {
    top: 0;
    position: relative;
    transition: all 0.5s;
    height: auto !important;
  }


}

.rewe-group {
  .start-banner {

    .title {
      @include overlay();
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .inner {
        @include fluid-type(padding-top, 320px, $breakpoint-md, 25px, 55px);
        color: #fff;

        @include media-breakpoint-up(lg) {
          padding-top: 37px
        }

        @include media-breakpoint-up(xl) {
          padding-top: 55px
        }

        transition: all 0.5s;

        h1 {
          text-align: center;
          font-weight: bold;
          @include fluid-type(font-size, 320px, $breakpoint-sm, 10px, 20px);

          margin: 0;
          font-family: var(--font-headline);
          text-transform: uppercase;
          transition: all 0.5s;
        }
      }
    }

    &:hover {
      filter: saturate(1.2) brightness(1.05);

      .wrap-svg {
        top: -5px;
      }

      .title .inner {
        padding-bottom: 3em;

        h1 {
          font-size: 1.3em;
        }
      }
    }

    &.inverted {
      .title .inner {
        color: $color-gray;
      }
    }

  }
}

.rewe-digital {
  .start-banner {
    box-sizing: border-box;
    border: 10px solid white;
    margin-top: 0 !important;
    // border-radius: 17px;

    .jpg-preview {
      width: 100%;
      height: auto;
    }

    .wrap-svg {
      transform: scale(1.00);
      transition: all 0.5s;
      transform-origin: center;
    }

    &:hover {
      filter: saturate(1.2) brightness(1.05);

      .wrap-svg {
        transform: scale(1.05);
      }

    }


    .title {
      @include overlay();
      right: 60%;
      left: 30px;
      display: flex;
      justify-content: left;
      align-items: center;

      .inner {
        color: #00195b;

        transition: all 0.5s;

        h1 {
          text-align: left;
          font-weight: bold;
          @include fluid-type(font-size, 320px, $breakpoint-sm, 20px, 30px);

          font-family: var(--font-headline);
          text-transform: uppercase;
          transition: all 0.5s;
        }
      }
    }
  }
}


.contact {

  input,
  textarea {
    margin-bottom: 1em;
    border-radius: 0;
    border: none;
    background: #f4f4f4;
  }

  input[type="submit"] {
    background: #353535;
    color: #fff;
    font-weight: bold;
    max-width: 200px;
  }
}

.page-start {
  @include media-breakpoint-down(sm) {
    padding-left: 1em;
    padding-right: 1em;
  }
}
