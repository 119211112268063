.layout {

    >*:not(.illustration) {
        padding-top: 2em;
        padding-bottom: 3em;
    }

    h2,h3 {
        text-align: center;
    }

    
    h3 {
        text-align: center;
        margin-bottom: 1em;
        font-size: 2.0em;
    }

    .core-paragraph, .core-quote {
        max-width: 50em;
        margin: 0 auto;
    }

    &.layout-nachhaltigkeit {

        &,a { 
            color: #fff;
        }

        blockquote{
            p {
                font-family: var(--font-copy);
                font-size: 200%;
                line-height: 100%;
                margin-bottom: 1em;
            }
            cite {
                font-weight: normal;
                font-style: normal;
                display: block;
                padding-left: 30%;
            }
            em {
                font-style: normal;
                line-height: 1;
                font-size: 24px;
            }
        } 


        .core-list {
            ul {
                margin: 0 auto;
            }

            li {
                list-style: none;
                @include desktop {
                    display: inline-block;            
                    width: 50%;
                    &:nth-child(1), &:nth-child(3) {
                        padding-left: 25%;
                    }
                }
            }
        }

        p a, li a {
            position: relative;
            display: inline-block;
            font-weight: bold;
            padding-left: 55px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 1em;
            word-wrap: none;
            &:before {
                left: 0;
                top: 0;
                position: absolute;
                content: "";
                display: block;
                background: url(/build/images/arrow-circle-white.svg) no-repeat;
                width: 38px;
                height: 38px;
            }
    
        }

        .core-image {
            margin: 0 auto;
            max-width: 200px;
        }

        .core-paragraph {
            margin-bottom: 2em;
        }
        

        >*:nth-child(1) { background-color: #46a4f2;  padding-top: 5em }
        >*:nth-child(2) { background-color: #46a4f2; }
        >*:nth-child(3) { background-color: #558b2f; }
        >*:nth-child(4) { background-color: #558b2f; }
        >*:nth-child(5) { background-color: #254d16; }
        >*:nth-child(6) { background-color: #254d16; }
        >*:nth-child(7) { background-color: #cc8648; }
        >*:nth-child(8) { background-color: #cc8648; }
        >*:nth-child(9) { background-color: #0588d1; padding-top: 5em }
        >*:nth-child(10) { background-color: #7d9b12; }
        >*:nth-child(11) { background-color: #9a8c81; }
        >*:nth-child(12) { background-color: #9a8c81; }
        >*:nth-child(13) { background-color: #7d9b12; }
        >*:nth-child(14) { background-color: #ffffff; }
    }

    &.layout-leitbild {



        >*:nth-child(1) { background-color: #fff; }
        >*:nth-child(2) { background-color: #fff; }
        >*:nth-child(3) { background-color: #f1f1f1; }
        >*:nth-child(4) { background-color: #fff; }
        >*:nth-child(5) { background-color: #fff; }
        >*:nth-child(6) { background-color: #fff; }
        >*:nth-child(7) { background-color: #f1f1f1; }
        >*:nth-child(8) { background-color: #fff; }
        >*:nth-child(9) { background-color: #fff; }
        >*:nth-child(10) { background-color: #fff; }
        >*:nth-child(11) { background-color: #f1f1f1; }
        >*:nth-child(12) { background-color: #fff; }
        >*:nth-child(13) { background-color: #fff; }
        >*:nth-child(14) { background-color: #fff; }
        >*:nth-child(15) { 
            background-color: #f1f1f1; 
            .core-heading {
                text-align: center;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 170px;
                    height: 148px;
                    background-size: contain;
                    background-position: center;
                    background-image: url(/build/images/concha.jpg);
                    background-repeat:  no-repeat;
                    margin: 1em 0;
                }
            }
        }
    }




    .illustration {
        margin-top: -2px;
        margin-bottom: -2px;
        overflow: hidden;
        img {
            margin-left: -2px;
            margin-right: -2px;
            width: calc(100% + 4px);
            height: 100%;
            object-fit: cover;
        }
    }

    &.layout-struktur {

        .chunk {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }
            &:not(:last-child) {
                border-bottom: 2px solid #eee;
            }
        }

        h2 {
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            line-height: 120%;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                margin-top: 1em;
                width: 220px;
            }
            
        }
         
        .core-gallery .container {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            figure {
                display: flex;
                width: auto;
                max-width: 100px;
                max-height: 50px;
                margin: 0;
                @include media-breakpoint-up(md) {
                    zoom: 1.5;
                    position: relative;
                    transition: top 0.5s;
                    top: 0;
                    &:hover {
                        top: -5px;        
                    }
                }

                @include media-breakpoint-up(xl) {
                    margin-right: 0.5em;
                    margin-bottom: 0.5em;
                }

            }

            .break {
                flex-basis: 100%;
                height: 0;
            }
        }

        .core-image:not(:last-child) {
            padding-bottom: 1em;
            margin-bottom: 1em;
        }

        img[src$='.jpg'], img[src$=".png"] {
            object-fit: contain;
        }

    }

}

@include mobile {
    .lazyblock-layout > .container {
        padding-right: 0;
        padding-left: 0;
        max-width: none !important;
    }
}

.rewe-group {
    .layout.layout-struktur h2 {
        font-family: trade-gothic-next-pro;
    }
}

.rewe-digital {
    .layout.layout-struktur h2 {
        font-family: var(--font-headline);
    }
}
