.magazine {

    margin-top: 5em;

    .col {
        box-sizing: border-box;
    }

    .carousel-block {
        h3 { 
            text-align: left;
            }
            .carousel-control-prev, .carousel-control-next {
                background: transparent;
            }

        .panel {
            padding: 0 2em;
            figure {
                border-radius: 100%;
                $size: 12em;
                width: $size;
                height: $size;
                border: 0.5em solid white;
                overflow: hidden;
                padding-bottom: 0;
            }
            .text {
                &:before {
                    display: none;
                }
                min-height: 16em;
                padding-left: 0;
            }
        }
    }

}