$time: 0.25s;

li {

    @include desktop() {
        &.active {
            .submenu {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.rewe-group {
    li {
        &:nth-child(1) .submenu {
            background-color: $color-yellow;

            ul>li {
                border-bottom: 1px solid rgba(0, 0, 0, .1) !important;

                >a {
                    color: #000;
                }
            }
        }

        &:nth-child(2) .submenu {
            background-color: $color-wine;
        }

        &:nth-child(3) .submenu {
            background-color: $color-gray;
        }

        &:nth-child(4) .submenu {
            background-color: $color-green;
        }
    }


    @include desktop() {
        .submenu .container {
            padding: 4em 0;
        }
    }


    .submenu {

        @include desktop() {

            position: absolute;
            left: 0;
            right: 0;
            top: 75px;

            visibility: hidden;
            opacity: 0;
            transition: visibility $time linear, opacity $time linear;
            z-index: 100;

            &.hidden {
                visibility: hidden !important;
                opacity: 0 !important;
            }

            .container {
                padding: 4em 0;

            }


        }


        ul {
            padding: 0;
            margin: 0;
        }

        li {
            box-sizing: border-box;
            display: inline-block;

            width: 100%;

            @include desktop() {
                width: calc(100% / 3 - 2em);
                margin: 0 1em;
            }

            height: auto;
            line-height: 27px;
            text-align: left;
            transition: .3s ease-in-out;

            border-bottom: 1px solid rgba(255, 255, 255, .3) !important;

            a {
                display: block;
                padding: 14px 14px 14px 30px;
                width: 100%;
                text-decoration: none;
                font-weight: bold;
                transition: .3s ease-in-out;
                color: #fff;

                &.active {
                    background: rgba(255, 255, 255, .2);
                }
            }

            &:hover {
                background-color: #fff;

                a {
                    color: #706f6f;
                }
            }

        }



    }
}

.rewe-digital {

    li {
        .submenu {
            background-color: white;

            li {
                a {
                    color: #000 !important;
                }
            }
        }
    }


    @include desktop() {
        .submenu .container {
            padding: 1.5em 0;
        }
    }

    .submenu {

        @include desktop() {

            position: absolute;
            left: 0;
            right: 0;
            top: 80px;

            visibility: hidden;
            opacity: 0;
            transition: visibility $time linear, opacity $time linear;
            z-index: 100;

            &.hidden {
                visibility: hidden !important;
                opacity: 0 !important;
            }

            .container {
                padding: 4em 0;
            }

            .close-level {
                display: none;
            }
        }


        ul {
            padding: 0;
            margin: 0;

            @include desktop() {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
            }

        }

        li {
            box-sizing: border-box;
            display: inline-block;

            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, .3);
            height: auto;
            line-height: 27px;
            text-align: left;

            @include desktop() {
                margin: 0 1em 0 0;

                // https://keithclark.co.uk/articles/targeting-first-and-last-rows-in-css-grid-layouts/
                &:nth-child(2n+1):nth-last-child(-n+2),
                &:nth-child(2n+1):nth-last-child(-n+2)~&,
                &:last-child {
                    border-bottom: none !important;
                }
            }

            a {
                display: block;
                padding: 14px 14px 14px 30px;
                width: 100%;
                color: #000;
                text-decoration: none;
                font-weight: bold;
                transition: .3s ease-in-out;

                &.active {
                    background: rgba(255, 255, 255, .2);
                }
            }

            &:hover {
                background-color: #fff;
            }

        }

    }

}
