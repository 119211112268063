.wall {

    box-sizing: border-box;

    .wall-entry {
        box-sizing: border-box;
        display: inline-block;
        @include desktop() {
            width: calc((100% / 3) - 2em);
            margin: 0 1em 1em 0;
        }
        @include mobile() {
            width: 100%;
            margin-bottom: 2em;
        }
    }
}