.lazyblock-link {
    text-align: center;
    max-width: 300px;
    margin: 0 auto;

    .bi {
        margin-left: 1em;
        z-index: 1;
        position: relative;
    }
}


.frame {
    .lazyblock-link {
        margin-left: 0;
    }
}