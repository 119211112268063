@include desktop {
    .carousel-item {
        min-height: $height-slider;

        img {
            height: $height-slider;
        }
    }



    .height-long {
        .carousel-item {
            min-height: $height-slider-long;
        }


    }

    .height-short {
        .carousel-item {
            min-height: $height-slider-short;
        }
        
        .item-slide {
            figure {
                height: $height-slider-short;
            }
        }
    }

    .item-slide {
        figure {
            height: $height-slider;
        }
    }
    
    .height-long {
    
        .item-slide {
            figure {
                height: $height-slider-long;
            }
        }
    
    }
    
    .height-short {
        .item-slide {
            figure {
                height: $height-slider-short;
            }
        }
    }

}



figure {
    position: relative;

    .image-desc {
        text-transform: uppercase;
        font-size: 70%;
        position: absolute;
        right: 20px;
        bottom: 0;
        text-shadow: 0 0 10px #000;
        color: #fff;
        z-index: 1000;

        a {
            color: #fff !important;

            &:hover {
                color: #eee;
            }

            &:before {
                content: "© ";
            }
        }

        .attachment {

            display: none;
        }
    }

    .image-caption {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;

        .inner {
            color: #000;
            background: rgba(#fff, 0.8);
            margin: 0 8em;
            padding: 1em 4em 1em 4em;
        }
    }

    .image-edit {
        position: absolute;
        z-index: 1;
        right: 1em;
        top: 1em;
    }

}

.frame {
    .image-caption {
        display: none;
    }
}

.carousel.slide {
    padding-bottom: 1em;
}


.carousel-control-prev,
.carousel-control-next {
    width: 5%;
    opacity: 1 !important;
}

.carousel-control-prev,
.carousel-control-next {

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        opacity: 0.8;
    }

    &:hover {

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            opacity: 1;
        }

    }
}

.rewe-group {
    .carousel-control-prev-icon {
        background-image: url(/build/images/slider-prev.svg);
        width: 40px;
        height: 40px;
    }
    
    .carousel-control-next-icon {
        background-image: url(/build/images/slider-next.svg);
        width: 40px;
        height: 40px;
    }
}

.rewe-digital {
   
    .carousel-control-prev {
        min-width: 55px;
        margin-left: 5px;
    }
    
    .carousel-control-next {
        min-width: 55px;
        margin-right: 5px;
    }

    .carousel-control-prev-icon {
        background-image: url(./images/arrow-left.svg);
    }
    
    .carousel-control-next-icon {
        background-image: url(./images/arrow-right.svg);
    }
    
    .carousel-control-prev-icon,.carousel-control-next-icon {
        opacity: 1 !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
        background-color: white;
        width: 55px;
        height: 55px;
        border: 1px solid black;
        border-radius: 100%;
        display: flex;
    }

    @include mobile() {
        .carousel-control-prev {
            min-width: 30px;
            margin-left: 0;
        }
        
        .carousel-control-next {
            min-width: 30px;
            margin-right: 0;
        }
    

        .carousel-control-prev-icon,.carousel-control-next-icon {
            width: 30px;
            height: 30px;
        }

    }
}


.carousel-indicators {

    li {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #000;
        opacity: 0.2;

        @include desktop() {
            margin-right: 10px;
            margin-left: 10px;
    
        }
    }
}

.rewe-digital {

    .carousel-indicators {
        li {
            width: 16px;
            height: 16px;
            background: white;
            opacity: 1;
            border: 1px solid black;
            &.active {                
                background: black;
            }
        }
    }
}

.panel {

    a {
        color: #000 !important;

        &:hover,
        &:hover * {
            opacity: 0.8 !important;
        }
    }

    $size: 40px;

    figure {
        @include media-breakpoint-up(md) {
            margin-left: 2em;
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
        width: calc(100% - 6em);
        height: 0;
        padding-bottom: 50%;
        position: relative;
        margin-bottom: 0.5em;

        img {
            position: absolute;
            width: auto;
            min-width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left center;
            background: #fff;
        }

        .image-desc {
            left: 20px;
            right: auto;
        }
    }

    .text {
        padding-left: 0;
        @include media-breakpoint-up(md) {
            padding-left: 2em;
        }
        padding-bottom: 1em;
        position: relative;
        min-height: 12em;

        h3 {
            font-size: 16px;
            line-height: 130%;
            font-weight: bold;
            margin-bottom: 0;
        }

        .link {
            margin-top: 2.5em;
        }

        .link-btns {
            position: absolute;
            bottom: 0;
        }
    }

}

 
.hide-description {

    .panel .text {
        min-height: auto !important;
        > span {
            display: none;
        }

    }
    
    .carousel-item {
        min-height: $height-slider-short !important;    
    }
    
}

.item-slide {
    position: relative;
    color: #fff;

    h3 {
        font-family: var(--font-copy);
    }

    figure {
        width: 100%;
        background: antiquewhite;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        padding: 5% 10%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

}

.rewe-group {
    .item-slide {
        a {
            color: #fff;
            &:hover>* {
                opacity: 0.9 !important;
            }
        }
    }
}


.rewe-digital {
    .item-slide {
        a {
            color: black;
            &:hover>* {
                opacity: 1 !important;
            }
        }
    }
}



.panel-slide {
    margin-left: 5%;
    margin-right: 5%;

    ul {
        font-size: 90%;
        padding-left: 1em;
    }
}


.carousel-indicators {
    bottom: -45px;
}

.image-caption {
    p {
        margin-bottom: 0;
    }
}



.carousel-block,
.lazyblock-map {
    margin-bottom: 50px;
    position: relative;

    .frame {
        color: #000;

        @include mobile() {
            padding-top: 1em;
            @include make-container();
            
        }

        @include desktop() {
            @include overlay();

            .inner {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(#fff, 1);
                margin: 0 12em;
                @include media-breakpoint-down(lg) {
                    margin: 0 6em;
                }                
                padding: 0.5em 4em;
                z-index: 1;
                h2 {
                    margin: 0.3em 0 0.1em 0;
                    font-size: 2em;
                }
            }
        }
    }
}

.rewe-digital {
    
    .carousel-block,
    .lazyblock-map {

        .frame {
            @include desktop() {

                .inner {
                    margin: 0 10em;
                    padding: 0.5em 4em;
                }
            }
        }
    }
}

.carousel-block.genossenschaft {
    .frame {
        top: 0 !important;
        bottom: auto !important;
        .inner {
            background: transparent;
            bottom: auto;
            z-index: 1;
        }
    }
}

.carousel-block.genossenschaft-quer {
    @include desktop() {
        display: block;
    }

    @include mobile() {
        display: none;
    }    


}

.carousel-block.genossenschaft-hoch {
    @include desktop() {
        display: none;
    }

    @include mobile() {
        display: block;
    }    

    position: relative;

    .frame {

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 1em;
            z-index: 1;
            h2 {
                margin: 1em 0;
                font-size: 4.5vw;
            }
        }
    }
}


.carousel-block.inverted {
    padding: 2em;
    background: black;
    * {
        color: white;
    }
    .panel figure img {
        background: transparent;
    }
    .carousel-indicators * {
        background-color: white;
    }
}
.post-id-396 .carousel-block.inverted {
    background: $color-gray;
}

