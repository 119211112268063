.youtube, .vimeo {

    max-width: 600px;
    margin: 0 auto;
    
    .iframe-wrap {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    figure {
        strong {
            text-transform: uppercase;
        }
        margin-top: 0.5em;
    }

    .wp-block-embed__wrapper {
        display: none;
    }
}

.post > {
    .core-embed-vimeo, .core-embed-youtube {
        width:100vw;
        position:relative;
        left:calc(-1 * (100vw - 100%)/2);
        background: black;
        padding: 3em 0 2em 0;
    }
}