body {
  font-family: var(--font-headline);
  background: #fff;
  color: #000;
  line-height: 140%;
  overflow-x: hidden;
  padding-top: 54px;
}

.rewe-digital main {
  padding-top: 20px;
}

h1,
h2 {
  font-family: var(--font-copy);

  &.has-large-font-size {
    font-size: 50px;
  }
}

.rewe-digital {

  h1,
  h2 {
    line-height: 100%;
  }
}

.btn,
.btn-primary,
.btn-secondary {
  border: none;

  &:focus {
    box-shadow: none;
  }
}

figure,
img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

a:hover {
  text-decoration: none !important;
}


.rewe-group {

  .section.arrow {
    width: 60px;
    height: 60px;

    &.arrow-up {
      background: url(/build/images/section-up.svg);
    }

    &.arrow-down {
      background: url(/build/images/section-down.svg);
      margin-top: 1em;
    }

    display: inline-block;
    cursor: pointer;
  }


  .arrow-left {
    width: 40px;
    height: 40px;
    background: url(/build/images/arrow-left.svg);
    display: inline-block;
  }

}

.rewe-digital {

  .section.arrow {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    background-color: white;
    border: 1px solid black;
    border-radius: 100%;
    display: inline-block;

    width: 60px;
    height: 60px;

    &.arrow-up {
      background-image: url(./images/arrow-up.svg);
    }

    &.arrow-down {
      background-image: url(./images/arrow-down.svg);
      margin-top: 1em;
    }

    display: inline-block;
    cursor: pointer;
  }


  .arrow-left {
    opacity: 1 !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    background-color: white;

    background-image: url(./images/arrow-right.svg);
    width: 55px;
    height: 55px;
    border: 1px solid black;
    border-radius: 100%;
    display: inline-block;
  }

}


.section {
  position: absolute;
  left: 50%;
  margin-left: -30px;
  transition: opacity 0.5s;
  opacity: 0;
}

.expanded .section {
  opacity: 1;
}


.expand {
  cursor: pointer;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  >* {
    margin: 0 0.4em;
  }

  transition: opacity 0.5s;
  opacity: 1;
  padding-top: 20px;

  position: relative;

  span {
    position: relative;
    left: 0;
    transition: left 0.5s;

    @include desktop() {
      transition-delay: 0.5s;
    }
  }

  .arrow {
    transform: rotate(0deg) scale(1);
    filter: brightness(100%);
    transition: all 0.5s;

    @include desktop() {
      transition-delay: 0.5s;
    }
  }

}

.post-expander:hover {
  .expand {
    span {
      left: 5px;
    }

    .arrow {
      transform: rotate(90deg) scale(1.2);
      filter: brightness(150%);
    }
  }
}

.expanded .expand {
  opacity: 0;
}

.expander {
  overflow: hidden;
}

.post {
  padding-top: 6em;
  padding-bottom: 0;
}

.wp-block-image {
  padding-top: 2em;
}

.admin-edit-link {
  z-index: 1000;
  position: relative;
  margin: 0 !important;
}

.svg-placeholder {
  height: 400px;
}

*:focus,
*:active {
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

main.container {
  padding-top: 0 !important;
}

@include desktop {
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.lazyblock-images .container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.jpg-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

.header {
  user-select: none;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include media-breakpoint-down(md) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.0;
  }
}


.tooltip p,
.alert p {
  margin-bottom: 0;
}

.alert {
  border-radius: 0;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  &:before,
  &:after {
    content: "";
    left: -20px;
    height: 20px;
    display: flex;
    position: absolute;

    background-image: linear-gradient(to right, white 50%, transparent 0%);
    background-position: right;
    background-size: 80px 20px;
    background-repeat: repeat-x;

    transform: skewX(45deg);
  }

  &:before {
    top: 0;
    width: calc(100% + 40px);
  }

  &:after {
    top: calc(100% - 20px);
    width: calc(100% + 40px);
  }


}

.alert-warning {
  background-color: $color-yellow;
  border-color: $color-yellow;
}
