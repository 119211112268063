$time: 0.25s;

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.favs {
    width: 100%;
    background: #eee;
    padding: 5em 0 2em 0;
    z-index: 50;
    animation: fadein $time;
    position: relative;

    .close-button, .dropdown {
        display: inline-block
    }

    .col-close {
        @include media-breakpoint-down(sm) {
            order: -1
        }
    }
    
    .list {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .fav {
        display: flex;
        width: 100%;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        
        background: white;
        align-items: center;
        margin-bottom: 1em;

        figure {

            &.placeholder {
                background: beige;
            }

            img {
                object-fit: cover;
            }

            .image-caption {
                display: none;
            }

            margin: 0;
            box-sizing: border-box;

            @include media-breakpoint-up(lg) {
                margin-right: 50px;
                padding: 2em 1em;
            
        
                &,img {
                    max-width: 250px;
                }
    
            }
            @include media-breakpoint-down(md) {
                width: 10em;
                max-width: 100%;
                padding: 0.5em;
                img {
                    width: 100%;
                }

            }

        }

        .text {
            @include media-breakpoint-up(lg) {
                margin: 1em 2em 1em 0;
                flex-grow: 1;
            }
            @include media-breakpoint-down(md) {
                padding: 1em;
                box-sizing: border-box;
                width: 60%;
            }    
            @include media-breakpoint-down(sm) {
                width: auto;
            }
        }

        .link {
            margin: 0 2em 2em 2em;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(md) {
                width: 100%;
                flex-basis: 100%;
            }    
        }

    }
}

.rewe-digital {
    .favs .fav .link a {
        color: #000;
    }
}
