.opener {

  @include desktop() {
    position: relative;
    height: 480px;
  }

  .overlay {
    @include desktop() {
      @include overlay();
    }

    @include mobile() {
      margin-top: 0.5em;
    }
  }

  .container {
    height: 100%;
  }

  .title {
    display: flex;
    height: 100%;
  }

  .wrap-svg {
    max-width: 1600px;
    margin: 0 auto;
  }

  &.opener-primary {

    .wrap-svg {
      max-width: 1600px;

      @include media-breakpoint-up(md) {

        object,
        img {
          height: 480px;
        }
      }
    }

    .title {

      .inner {
        color: #fff;

        @include desktop() {
          padding: 0 50% 0 8em;
        }

        align-self: center;

        h2 {
          font-size: 3em;
          margin-bottom: 0.5em;
          font-family: var(--font-headline);
          text-transform: uppercase;
        }
      }
    }

    &.inverted {
      .title {
        .inner {
          color: #000;
        }
      }
    }


    @include media-breakpoint-down(md) {
      .title {
        .inner {
          color: #000;
        }
      }
    }

  }

  &.opener-secondary {

    overflow: hidden;
    cursor: pointer;

    @include desktop() {
      & {
        height: 360px;
        transition: all 1s;
      }

      .wrap-svg {
        height: 390px;
        transition: all 1s;
      }
    }

    .wrap-svg {
      max-width: 1140px;

      @include desktop() {
        margin-top: -85px;
        padding-left: 12em;
        padding-right: 12em;
      }
    }

    .title .inner {
      h2 {
        margin: 0.3em 0 0.1em 0;
        font-size: 2em;
      }

      p {
        padding-bottom: 0 !important;
        margin-bottom: 0;
      }
    }


    @include desktop() {

      .title {
        @include overlay();
        position: relative;

        .inner {
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          align-self: center;
          background: rgba(#fff, 1);
          padding: 0 4em;
          margin: 0 12em;

          @include media-breakpoint-down(lg) {
            margin: 0 6em;
          }

          color: #000;
        }
      }
    }

  }

}

.post-expander {
  margin-top: 3em;
  margin-bottom: 6em;

  @include desktop() {

    &:hover,
    &.expanded {
      .opener.opener-secondary {

        &,
        .wrap-svg {
          margin-top: 0;
          height: 480px;
        }

        .wrap-svg {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.rewe-group {
  .post-expander {
    @include desktop() {

      &:hover,
      &.expanded {
        .opener.opener-secondary {
          .wrap-svg {
            filter: saturate(150%);
          }
        }
      }
    }
  }
}

.rewe-digital {
  .opener.opener-primary {
    .wrap-svg {
      .jpg-preview {
        object-fit: contain;
      }
    }

    &.section-1 {
      background: #e0f6fb;
    }

    &.section-2 {
      background: #d2eb23;
    }

    &.section-3,
    &.section-4 {
      background: #ffffdc;
    }
  }

  .protection-image {
    .opener.opener.opener-secondary {
      .wrap-svg {
        .jpg-preview {
          object-fit: contain;
        }
      }
    }
  }
}
