$color-link: rgb(112, 111, 111);

nav {
    font-weight: normal;
}


.rewe-group .navbar-nav {
    @include desktop() {
        a {
            font-size: 14px;
        }
    }
}

.rewe-digital .navbar {
    min-height: 80px;
}

.rewe-digital .navbar-nav {
    @include desktop() {
        a {
            color: #000;
            font-size: 18px;
        }
    }
}


.navbar-nav {
    @include desktop() {

        flex-grow: 1;
        justify-content: flex-end;

        a {
            font-size: 14px;

            &.active {
                font-weight: bold;
            }
        }

        >ul {
            display: flex;
            flex-direction: row;

            >li {
                justify-self: flex-start;
                margin-left: 1em;
                display: block;

                >a {
                    display: inline-block;
                    color: #000;

                    background-color: rgba(0, 0, 0, 0);
                    box-sizing: border-box;
                    color: $color-link;

                    text-decoration: none;
                    text-decoration-style: solid;

                    padding: 0.5em 1em;
                    line-height: 62px;
                    white-space: nowrap;
                }

                &.fav-icon {
                    justify-content: flex-end;
                    align-items: center;
                    width: 50px;
                    height: 70px;
                }
            }
        }
    }

    @include mobile() {

        position: relative;
        height: calc(100vh - 54px);
        width: 100%;

        transition: left 0.5s;
        left: 0;

        &.level-1 {
            left: -100vw;
        }

        .submenu {
            position: absolute;
            left: 100vw;
            top: 0;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            small p {
                margin-bottom: 0;
            }

            >a {
                padding: 1.5em 0 1em 0;
                color: #000;
                font-size: 28px;
                width: 100%;
                text-align: center;
                display: block
            }

            >.close-level {
                position: absolute;
                padding: 2em 1em;
                color: #000;
                font-size: 18px;
                width: 10px;

                &:hover {
                    text-decoration: none;
                }

                &:before {
                    content: "\37";
                    font-family: rewegroup_icon;
                    font-style: normal;
                }
            }
        }

        >ul {
            padding: 0;
            margin: 0;

            >li {
                box-sizing: border-box;
                display: inline-block;

                width: 100%;
                height: auto;
                line-height: 27px;
                text-align: left;
                transition: .3s ease-in-out;

                >.submenu {
                    visibility: hidden;
                    transition: visibility 0.5s;
                }

                &.active {
                    >.submenu {
                        visibility: visible;
                    }
                }


                button {
                    display: block;
                    padding: 14px 14px 14px 30px;
                    width: 100%;
                    text-decoration: none;
                    font-weight: normal;
                    transition: .3s ease-in-out;
                    font-size: 28px;
                    display: block;
                    text-align: center;
                    color: rgb(112, 111, 111);

                    &.active {
                        background: rgba(255, 255, 255, .2);
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                &:nth-child(1) {
                    .submenu {
                        >a {
                            color: #000;
                        }

                        >.close-level {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.navbar {

    padding: 0;
    margin: 0;

    .container {
        @include media-breakpoint-down(md) {
            max-width: none !important;
        }
    }

    ul {
        padding: 0;
        margin: 0;
    }


}
