@mixin overlay() {
  position: absolute;
  left: 0;
  top: 0;    
  right: 0;
  bottom: 0;
}

$breakpoint-xs: map_get($grid-breakpoints, "xs");
$breakpoint-sm: map_get($grid-breakpoints, "sm");
$breakpoint-md: map_get($grid-breakpoints, "md");
$breakpoint-lg: map_get($grid-breakpoints, "lg");

@mixin mobile {
  @include media-breakpoint-down(md) {
    @content;
  }
};

@mixin desktop {
  @include media-breakpoint-up(lg) {
    @content;
  }
};

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
    //#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
  }


  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
